import React from 'react'

import Layout from '../components/layout'
import News from '../components/news'
import SEO from '../components/seo'

const NewsPage = ({ location }) => (
    <Layout location={location}>
        <SEO title="News" />
        <News />
    </Layout>
)

export default NewsPage
