import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Grid from '../components/grid'
import LoadMore from '../components/load-more-btn'
import remocoClient from '../remocoClient'
import { get, setGaScreenName } from '../utils'

const NUM_PROVIDERS_TO_ADD = 10

function getRelevantNewsProviderData(colls) {
    return colls
        .map(coll => {
            // check for series without entries
            const { id } = coll
            const { title, synopsis } = coll.data
            const poster = get(['data', 'resources', 'poster_21-9', 'value'], coll)
            const image = get(['data', 'resources', 'poster_16-9', 'value'], coll)

            return {
                id,
                ctitle: title,
                description: synopsis,
                marketingTitle: get(['data', 'marketing_title'], coll),
                poster,
                image,
                slug: `s/${id}`,
                isSeries: true,
            }
        })
        .filter(coll => !!coll) // filter out empty colls
}

async function getNewsProviderData(collGroup) {
    const entries = get(['data', 'entries', 'list'], collGroup) || []

    return Promise.all(
        entries.map(async item => {
            const { id, type } = item
            // just get the series image without any episode data
            const expandLevel = 1

            let newsProviderData = {}

            if (type === 'LibrarySeries') {
                newsProviderData = await remocoClient.getSeriesById(id, expandLevel).catch(err => {
                    console.error('Error retrieving collection:', err)
                    navigate('/404')
                })
            } else if (type === 'AdminCategory') {
                newsProviderData = await remocoClient.getCategory(id, expandLevel).catch(err => {
                    console.error('Error retrieving category:', err)
                    navigate('/404')
                })
            }

            return newsProviderData
        })
    )
}

const loadMoreNewsProviders = async (newsProviders, offset, setOffset, setNewsProviders, setShowLoadBtn) => {
    const newOffset = offset + NUM_PROVIDERS_TO_ADD

    await getNewsCollection(newOffset, newsProviders, setNewsProviders, setShowLoadBtn)

    setOffset(newOffset)
}

const getNewsCollection = async (offset, newsProviders, setNewsProviders, setShowLoadBtn) => {
    const limit = NUM_PROVIDERS_TO_ADD

    await remocoClient
        .getCollection('news', 1, limit, offset)
        .then(async newsColl => {
            const nextP = await getNewsProviderData(newsColl)
            const nextNewsProviders = getRelevantNewsProviderData(nextP)
            const allNewsProviders = [...newsProviders, ...nextNewsProviders]
            const totalNumProvidersAvailable = get(['data', 'entry_count'], newsColl)

            if (
                allNewsProviders.length === totalNumProvidersAvailable ||
                nextNewsProviders.length < NUM_PROVIDERS_TO_ADD
            ) {
                setShowLoadBtn(false)
            }

            setNewsProviders(allNewsProviders)
        })
        .catch(err => {
            console.log('Caught error retrieving collection:', err)
        })
}

const News = () => {
    const [newsProviders, setNewsProviders] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [showLoadBtn, setShowLoadBtn] = useState(true)
    const [offset, setOffset] = useState(0)

    useEffect(() => {
        setGaScreenName('News')
    }, [])

    useEffect(() => {
        getNewsCollection(0, [], setNewsProviders, setShowLoadBtn).then(_ => {
            setIsLoading(false)
        })
    }, [])

    if (isLoading) {
        return null
    }

    return (
        <NewsContainer>
            <Title>News</Title>
            <Grid data={newsProviders} useEditorialFields />
            {showLoadBtn ? (
                <LoadMore
                    onClickHandler={async () =>
                        await loadMoreNewsProviders(newsProviders, offset, setOffset, setNewsProviders, setShowLoadBtn)
                    }
                />
            ) : (
                ''
            )}
        </NewsContainer>
    )
}

const NewsContainer = styled.div`
    padding: 0 var(--news-page-margin-desktop);
    flex-grow: 1;

    @media screen and (min-width: 672px) {
        > .grid {
            grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
            /* vertically align news items with page title */
            margin: 0 -5px;
        }
    }

    @media screen and (max-width: 672px) {
        padding: 0 var(--news-page-margin-mobile);
    }
`

const Title = styled.h1`
    margin-top: 152px;
    margin-bottom: 24px;
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 44px;

    @media screen and (max-width: 672px) {
        margin-top: 76px;
        font-size: 17px;
        letter-spacing: 0.1px;
        line-height: 20px;
    }
`

export default News
